import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import Login from "./Login";
import SignUp from "./SignUp";
import Dashboard from "./Dashboard";
import { signInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification } from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setEmailVerified(user.emailVerified);
      } else {
        setUser(null);
        setEmailVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      setEmailVerified(userCredential.user.emailVerified);
      if (!userCredential.user.emailVerified) {
        alert("メールアドレスが確認されていません。確認メールを再送信しました。メールを確認してください。");
        await sendEmailVerification(userCredential.user);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSignUp = () => {
    setIsSigningUp(false);
  };

  return (
    <div className="App">
      {user ? (
        emailVerified ? (
          <Dashboard user={user} />
        ) : (
          <div>
            <p>メールアドレスが確認されていません。メールを確認してください。</p>
            <button onClick={async () => await sendEmailVerification(user)}>確認メールを再送信</button>
            <button onClick={() => auth.signOut()}>ログアウト</button>
          </div>
        )
      ) : isSigningUp ? (
        <SignUp onSignUp={handleSignUp} onBackToLogin={() => setIsSigningUp(false)} />
      ) : (
        <Login onLogin={handleLogin} onSignUp={() => setIsSigningUp(true)} />
      )}
    </div>
  );
}

export default App;

