import React, { useState } from "react";
import "./Login.css";

const Login = ({ onLogin, onSignUp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await onLogin(email, password);
    } catch (error) {
      alert("ログインに失敗しました: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>ログイン</h2>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit" disabled={loading}>{loading ? "ログイン中..." : "ログイン"}</button>
        <div className="signup-link">
          <span onClick={onSignUp}>新規登録はこちら</span>
        </div>
      </form>
    </div>
  );
};

export default Login;

